<template>
  <div class="leave-review">
    <div class="leave-review__body">
      <div class="leave-review__title">Leave a review</div>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        action="/pages/leave-review"
        class="leave-review__form"
      >
        <v-text-field
          v-model="fullName"
          hide-details="auto"
          :rules="fullNameRules"
          label="Full Name"
          required
        ></v-text-field>
        <v-text-field
          v-model="company"
          hide-details="auto"
          :rules="companyRules"
          label="Company"
          required
        ></v-text-field>
        <v-text-field
          v-model="email"
          hide-details="auto"
          :rules="emailRules"
          label="Email"
          required
        ></v-text-field>
        <v-text-field
          v-model="contactNumber"
          hide-details="auto"
          :rules="contactNumberRules"
          label="Contact Number"
          required
        ></v-text-field>
        <v-textarea
          name="input-7-4"
          label="Your review"
          v-model="review"
        ></v-textarea>
      </v-form>
      <RoundedButton @click.native="send" :type="'primary'" :disabled="!valid"
        >Submit</RoundedButton
      >
    </div>
  </div>
</template>

<script>
import RoundedButton from "./RoundedButton.vue";

export default {
  components: {
    RoundedButton,
  },
  data: () => ({
    valid: true,
    review: "",

    contactNumber: "",
    contactNumberRules: [],

    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],

    fullName: "",
    fullNameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],

    company: "",
    companyRules: [(v) => !!v || "Company is required"],
  }),
  methods: {
    async send() {
      this.$refs.form.validate();

      if (this.$refs.form.validate(true)) {
        this.$store
          .dispatch("app/sendReview", {
            email: this.email,
            contactNumber: this.contactNumber,
            review: this.review,
            fullName: this.fullName,
            company: this.company,
          })
          .then((response) => {
            this.email = "";
            this.contactNumber = "";
            this.review = "";
            this.fullName = "";
            this.company = "";

            this.$emit("close");
          })
          .catch((err) => {
            console.error(err);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.leave-review {
  width: 100%;
  max-width: 890px;
  margin: 0px auto;

  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;

  &__body {
    width: 100%;
    height: 100%;

    padding: 40px 60px;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    background-color: #ffffff;
    border-radius: 10px;

    @media screen and (max-width: 470px) {
      padding: 35px 25px;
    }
  }

  &__title {
    @include adaptiv-font(48, 30);
    font-weight: 700;
    text-align: center;
    color: $primary-color;

    margin-bottom: 60px;

    @media screen and (max-width: 940px) {
      margin-bottom: 30px;
    }
  }

  &__form {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 30px;

    margin-bottom: 30px;

    @media screen and (max-width: 560px) {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    .v-textarea {
      grid-column: -1 / 1;
    }
  }
}
</style>
