<template>
  <div class="home">
    <section class="home__main">
      <div class="home__main-content">
        <div class="home__main-text">
          Streamlining healthcare journeys
          through dependable transport
          services, supporting clinics,
          hospitals, and family members
        </div>
        <RoundedButton :type="'white'" @click.native="autoScrollToDivAtCurrentIndex('home-info')">
          <template #icon-right>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.29289 15.7071C7.68342 16.0976 8.31658 16.0976 8.70711 15.7071L15.0711 9.34315C15.4616 8.95262 15.4616 8.31946 15.0711 7.92893C14.6805 7.53841 14.0474 7.53841 13.6569 7.92893L8 13.5858L2.34315 7.92893C1.95262 7.53841 1.31946 7.53841 0.928932 7.92893C0.538407 8.31946 0.538407 8.95262 0.928932 9.34315L7.29289 15.7071ZM7 -4.37115e-08L7 15L9 15L9 4.37115e-08L7 -4.37115e-08Z"
                fill="#3E688A" />
            </svg>
          </template>

          <template #default> Learn more about us </template>
        </RoundedButton>
      </div>
      <div class="home__main-video" style="background: rgb(81,115,146);">
        <video autoplay muted loop preload="auto">
          <source type="video/mp4" src="@/assets/videos/home/home_main.mp4" />
        </video>
      </div>
    </section>
    <section class="home__info" id="home-info">
      <div class="home__info-body">
        <div class="home__info-text">
          MedDrive.Health is committed to providing patients with a seamless, worry-free medical
          transportation experience, expertly coordinating pickups, drop-offs, and return trips.
          We take pride in partnering exclusively with experienced and professional chauffeurs,
          ensuring that our MedDrivers deliver the exceptional service our patients deserve.
          With a strong focus on patient well-being, our dedicated MedDriver team creates
          comfortable, supportive, and stress-free journeys, caring for patients throughout
          every leg of their journey.
        </div>
        <div class="home__info-media">
          <img v-if="!videoOpened" src="@/assets/images/home/home_info.png" alt="" />
          <video autoplay controls preload="auto" v-if="videoOpened">
            <source type="video/mp4" src="@/assets/videos/home/home_page_video2.mp4" />
          </video>
          <button @click="openVideo" v-if="!videoOpened">
            <div>
              <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15 9L-8.15666e-07 17.6603L-5.85621e-08 0.339745L15 9Z" fill="#3E688A" />
              </svg>
            </div>
          </button>
        </div>
      </div>
    </section>
    <RowBlock :items="services" :title="''" />
    <!-- <TimelineBlock :items="howItWorks" :title="'How it works'" :type="'primary'" /> -->
    <section class="home__testimonials">
      <div class="home__testimonials-body">
        <div class="home__testimonials-title">What our clients are saying</div>
        <v-carousel :show-arrows="false" light height="auto" class="home__testimonials-carousel">
          <v-carousel-item v-for="(item, i) in sliderItems" :key="i" class="home__testimonials-carousel-item">
            <div class="home__testimonials-carousel-text">
              {{ item.review }}
            </div>
            <div class="home__testimonials-carousel-company">
              <span>
                <div>{{ item.fullName }}</div>
                <div>{{ item.company }}</div>
              </span>
              <div class="info__company-img">
                <img src="@/assets/images/logo-full.png" alt="" />
              </div>
            </div>
          </v-carousel-item>
        </v-carousel>
        <div class="home__testimonials-button">
          <LeaveReviewDialog />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import LeaveReviewDialog from "../components/LeaveReviewDialog.vue";
import RoundedButton from "../components/RoundedButton.vue";

import RowBlock from "../components/RowBlock.vue";
import TimelineBlock from "../components/TimelineBlock.vue";

export default {
  name: "HomePage",
  components: {
    LeaveReviewDialog,
    RoundedButton,
    RowBlock,
    TimelineBlock,
  },
  data: () => ({
    videoOpened: false,
    services: [
      {
        img: "images/home/home_services-1.png",
        title: "",
        text: "Tailor-made transportation solutions catering to the needs of patients and caregivers, featuring our premium white-glove service.",
      },
      {
        img: "images/home/home_services-2.jpg",
        title: "",
        text: "Effortless ride scheduling through our user-friendly web platform dashboard for seamless management of patient rides and appointment details.",
      },
      {
        img: "images/home/home_services-3.jpg",
        title: "",
        text: "Real-time status tracking and updates for family members, caregivers and provider offices to stay informed.",
      },
      {
        img: "images/home/home_services-4.jpg",
        title: "",
        text: "Multilingual drivers to cater to diverse language needs, fostering effective communication.",
      },
    ],
    /* howItWorks: [
      {
        img: "images/home/home_how-it-1.png",
        title: "Healthcare Provider",
        text: "Using your online dashboard place an order for a ride with patient appointment reminders",
      },
      {
        img: "images/home/home_how-it-1.png",
        title: "Meddrive driver confirms pickup",
        text: "The assigned driver contact the member the day before the appointment to confirm and deliver any appointment reminders.",
      },
      {
        img: "images/home/home_how-it-2.png",
        title: "Round Trip",
        text: "We will pick up the patient wait for the appointment to end and drop them back off.",
      },
    ], */
    /* sliderItems: [
      {
        review: "Morbi nibh non urna purus sollicitudin scelerisque. At massa nullam at elementum dui. Tristique id quisque volutpat id. Semper urna tellus in turpis. Lorem ipsum dolor sit amet consectetur. Morbi nibh non urna purus sollicitudin scelerisque. At massa nullam at elementum dui. Tristique id quisque volutpat id. Semper urna tellus in turpis. Lorem ipsum dolor sit amet consectetur. ",
        fullName: "Test",
        company: "test Company"
      },
      {
        review: "Morbi nibh non urna purus sollicitudin scelerisque. At massa nullam at elementum dui. Tristique id quisque volutpat id. Semper urna tellus in turpis. Lorem ipsum dolor sit amet consectetur. Morbi nibh non urna purus sollicitudin scelerisque. At massa nullam at elementum dui. Tristique id quisque volutpat id. Semper urna tellus in turpis. Lorem ipsum dolor sit amet consectetur. ",
        fullName: "Test",
        company: "test Company"
      },
    ], */
    sliderItems: [],
  }),
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {
    this.getReviews();
    // try {
    //   screen.orientation.lock("portrait");
    // }
    // catch (err) {

    // }
  },
  methods: {
    async getReviews() {
      this.$store.dispatch("app/getReviews").then((response) => {
          for (let i = 0; i < response.length; i++) {
            const element = response[i];
            if (element.approved) {
              this.sliderItems.push(element);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        })
    },
    openVideo() {
      this.videoOpened = true;
    },
    autoScrollToDivAtCurrentIndex(id) {
      let el = document.getElementById(id);
      window.scrollTo(el.offsetLeft, el.offsetTop);
      this.nextElement++;
    },
  },
};
</script>

<style lang="scss">
.home {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  // background: white;
  &__main {
    width: 100%;
    position: relative;
    min-height: calc(100vh - 102px);

    display: flex;

    background: $primary-color;
  }

  &__main-content {
    width: 100%;
    max-width: 500px;

    position: relative;
    z-index: 2;

    margin: 30px 0 0 145px;

    @media screen and (max-width: 1200px) {
      margin: 60px 0 0 60px;
    }

    @media screen and (max-width: 550px) {
      margin: 40px 0 0 25px;
    }
  }

  &__main-text {
    @include adaptiv-font(34, 23);
    color: $white;
    font-weight: 500;
    line-height: 130%;

    margin-bottom: 40px;
  }

  &__main-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 550px) {
      top: 15%;
    }

    video {
      width: 100%;
      height: 100%;

      object-fit: contain;
    }
  }

  &__info {
    width: 100%;
    padding: 120px 0;

    @media screen and (max-width: 940px) {
      padding: 60px 0;
    }
  }

  &__info-body {
    width: 100%;
    max-width: $containerMaxWidth;
    margin: 0px auto;

    padding: 0 25px;
  }

  &__info-text {
    color: $primary-color;
    @include adaptiv-font(32, 20);
    line-height: 150%;
    font-weight: 600;
    text-align: center;

    margin-bottom: 80px;
  }

  &__info-media {
    width: 100%;
    max-width: 820px;

    margin: 0px auto;

    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    video {
      width: 100%;
      height: 100%;
    }

    button {
      width: 80px;
      height: 80px;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      background: $white;
      border-radius: 100%;

      &>div {
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        transition: all 0.2s linear;

        background: $white;
        border-radius: 100%;

        border: 2px solid $white;

        position: relative;
        z-index: 2;

        &:hover {
          color: $secondary-color;
          border: 2px solid $secondary-color;

          svg {
            width: 30px;
            height: 30px;

            margin-left: 8px;

            path {
              fill: $secondary-color;
            }
          }
        }
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        transform: translate(-50%, -50%);
        z-index: 1;

        background: $white;
        border-radius: 100%;
      }

      svg {
        width: 20px;
        height: 20px;

        margin-left: 3px;

        transition: all 0.1s linear;

        path {
          transition: all 0.2s linear;
        }
      }
    }
  }

  &__testimonials {
    width: 100%;
    padding: 100px 0;

    background: #e5f4ff;

    position: relative;
    z-index: 2;

    @media screen and (max-width: 940px) {
      padding: 60px 0;
    }
  }

  &__testimonials-button {
    margin-top: 40px;
  }

  &__testimonials-body {
    width: 100%;
    max-width: $containerMaxWidth;
    margin: 0px auto;

    padding: 0 25px;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
  }

  &__testimonials-title {
    @include adaptiv-font(48, 30);
    font-weight: 700;
    text-align: center;
    color: $primary-color;

    margin-bottom: 60px;
  }

  &__testimonials-carousel {
    width: 100%;
    max-width: 790px;
    margin: 0px auto;

    .v-responsive__content {
      display: flex;
      align-items: flex-end;
      justify-content: center;

      flex-direction: column;
    }

    .v-carousel__controls {
      background: none;

      .v-item--active {
        color: #3e688a;

        &::before {
          background: $primary-color;
          opacity: 1;
        }
      }

      button {
        width: 18px;
        height: 18px;

        opacity: 1;
        color: #e5f4ff;
        box-shadow: inset 0px 0px 6px rgba(62, 104, 138, 0.5);

        i {
          opacity: 1;

          &::after,
          &::before {
            display: none;
          }
        }
      }
    }
  }

  &__testimonials-carousel-item {
    padding-bottom: 80px;
  }

  &__testimonials-carousel-text {
    @include adaptiv-font(20, 17);
    line-height: 150%;
    text-align: center;

    margin-bottom: 30px;
  }

  &__testimonials-carousel-company {
    @include adaptiv-font(20, 17);
    line-height: 130%;
    color: #333333;

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      margin-right: 30px;

      &>div {
        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }

    img {
      width: 120px;
    }
  }
}
</style>
